import React, { useState, useEffect } from 'react';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import config from '../config'; // Importer config for å få tilgang til API-URL
import './ServiceAddressCheckpoint.css';

const ServiceAddressCheckpoint = ({ behandlingsadrId, token }) => {
    const [checkpoints, setCheckpoints] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!behandlingsadrId) {
            setError('behandlingsadrId is missing');
            setLoading(false);
            return;
        }

        const fetchCheckpoints = async () => {
            try {
                const response = await apiClient.get(
                    `${config.apiUrl}/getCheckpointByServiceAddress/${behandlingsadrId}?token=${token}`
                );
                setCheckpoints(response.data.data || []);  // Sjekker at dataen er definert
            } catch (err) {
                setError('Failed to fetch checkpoints');
            } finally {
                setLoading(false);
            }
        };

        fetchCheckpoints();
    }, [behandlingsadrId, token]);

    if (loading) {
        return <p>Loading checkpoints...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (!checkpoints || checkpoints.length === 0) {
        return <p>No checkpoints found.</p>;
    }

    return (
        <div className="cp-checkpoint-list">
            {checkpoints.map((checkpoint) => (
                <div key={checkpoint.checkpoint_id} className="cp-checkpoint-card">
                    <p><strong>{checkpoint.checkpoint_type}</strong></p>
                    <p>{checkpoint.room} - {checkpoint.exact_location}</p>
                    <p>{checkpoint.bait_type}</p>
                </div>
            ))}
        </div>
    );
};

export default ServiceAddressCheckpoint;
