// Dropdown som brukes i addresslistene til kjørebok
//Viser ikke optimal route om alle addresser er valgt
import React from 'react';

const SortSelector = ({ sortCriteria, handleSortChange, showOptimalRouteOption }) => {
  return (
    <div className="sort-container">
      <label htmlFor="sort-select">Sort by:</label>
      <select 
        id="sort-select" 
        value={sortCriteria} 
        onChange={(e) => handleSortChange(e.target.value)}
      >
        <option value="predefined">Predefined</option>
        <option value="priority">Priority</option>
        <option value="postnr">Postcode</option>
        {showOptimalRouteOption && (
          <option value="optimal">Optimal Route</option>
        )}
      </select>
    </div>
  );
};

export default SortSelector;
