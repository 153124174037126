import React, { useState, useEffect, useCallback } from 'react';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for axios
import { useNavigate } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import PageHeader from '../components/PageHeader';
import config from '../config';
import { FaSyncAlt, FaTrashAlt } from 'react-icons/fa';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './FutureJobs.css';

Modal.setAppElement('#root');

const FutureJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [jobToDelete, setJobToDelete] = useState(null);
  const [filter, setFilter] = useState('mine');
  const [searchQuery, setSearchQuery] = useState('');
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userid');
  const navigate = useNavigate();

  const loadJobs = useCallback(() => {
    setIsLoading(true);
    apiClient
      .get(`${config.apiUrl}/getFutureAssignmentNew?token=${token}`)
      .then((response) => {
        if (response.data.status === 'success') {
          console.log('Data fetched successfully:', response.data.data);
          setJobs(response.data.data);
        } else {
          console.error('Unexpected status:', response.data.status);
        }
      })
      .catch((error) => {
        console.error('Error fetching jobs:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [token]);

  useEffect(() => {
    if (token) {
      loadJobs();
    }
  }, [token, loadJobs]);

  const handleResume = (job) => {
    console.log('Resume job:', job);
    const { behandlingsadr_id, oppdrag_id } = job;

    apiClient.post(`${config.apiUrl}/resumeFutureJob/?token=${token}`, {
      user_id: userId,
      oppdrag_id: oppdrag_id,
      status_id: 1
    })
    .then(response => {
      if (response.data.status === 'success') {
        console.log('Job resumed successfully');
        navigate(`/legacy/CreateReport/${behandlingsadr_id}/${oppdrag_id}`);
      } else {
        console.error('Failed to resume job:', response.data.message);
        toast.error('Failed to resume job');
      }
    })
    .catch(error => {
      console.error('Error resuming job:', error);
      toast.error('Error resuming job');
    });
  };

  const openDeleteModal = (jobId) => {
    setJobToDelete(jobId);
    setModalIsOpen(true);
  };

  const closeDeleteModal = () => {
    setModalIsOpen(false);
    setJobToDelete(null);
  };

  const handleDelete = () => {
    if (!jobToDelete) return;

    apiClient.post(`${config.apiUrl}/cancelReport?token=${token}`, {
      oppdrag_id: jobToDelete,
      slettet: 1,
      status_id: 3
    })
    .then(response => {
      if (response.data.status === 'success') {
        console.log('Job deleted successfully');
        toast.success('Job deleted successfully');
        setJobs(jobs.filter(job => job.oppdrag_id !== jobToDelete));
        closeDeleteModal();
      } else {
        console.error('Failed to delete job:', response.data.message);
        toast.error('Failed to delete job');
      }
    })
    .catch(error => {
      console.error('Error deleting job:', error);
      toast.error('Error deleting job');
    });
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: '500px',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredJobs = jobs
    .filter(job =>
      (filter === 'mine' ? job.user_id === parseInt(userId) : filter === 'unassigned' ? !job.user_id : true) &&
      ((job.kundenavn && job.kundenavn.toLowerCase().includes(searchQuery.toLowerCase())) ||
       (job.behandlingsadr && job.behandlingsadr.toLowerCase().includes(searchQuery.toLowerCase())) ||
       (job.oppdrag_id && job.oppdrag_id.toString().includes(searchQuery)))
    )
    .sort((a, b) => new Date(a.dato_start) - new Date(b.dato_start)); // Sort by oldest date first

  return (
    <MainLayout title="Future Jobs">
      <PageHeader
        title=""
        description="On this page, you can view and manage upcoming jobs."
      />
      <div className="filter-container">
        <input
          type="text"
          placeholder="Search by customer name, address or task ID"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-box"
        />
        <select value={filter} onChange={handleFilterChange} className="filter-dropdown">
          <option value="mine">My Assignments</option>
          <option value="all">Show All Technicians</option>
          <option value="unassigned">Unassigned</option>
        </select>
      </div>
      <div className="tasks-container">
        {isLoading ? (
          <p>Loading...</p>
        ) : filteredJobs.length > 0 ? (
          filteredJobs.map((job) => (
            <div key={job.oppdrag_id} className="task-card">
              <div className="task-header">
                <h2>{`${job.kundenavn} - Task: ${job.oppdrag_id}`}</h2>
                <span>{formatDate(job.dato_start)}</span>
              </div>
              <div className="task-body">
                <div className="task-body-left">
                  <p>{job.behandlingsadr}</p>
                  <p>{job.type_besok}</p>
                  <p>{job.avtaletype}</p>
                  <p><strong>Technician:</strong> {job.navn}</p>
                </div>
                <div className="task-footer">
                  <button className="task-footer-button resume-button" onClick={() => handleResume(job)}>
                    <FaSyncAlt /> Start
                  </button>
                  <button className="task-footer-button delete-button" onClick={() => openDeleteModal(job.oppdrag_id)}>
                    <FaTrashAlt /> Delete
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No upcoming jobs.</p>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeDeleteModal}
        style={customStyles}
        contentLabel="Confirm Delete"
      >
        <h2>Confirm Delete</h2>
        <p>Are you sure you want to delete this job?</p>
        <button onClick={closeDeleteModal} className="task-footer-button resume-button">Cancel</button>
        <button onClick={handleDelete} className="task-footer-button delete-button">
          <FaTrashAlt /> Confirm Delete
        </button>
      </Modal>
      <ToastContainer />
    </MainLayout>
  );
};

export default FutureJobs;
