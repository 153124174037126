import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './CreateReport.css';
import MainLayout from '../../components/MainLayout';
import { FaCamera } from 'react-icons/fa';
import apiClient from '../../api/apiClient'; // Importer vår apiClient i stedet for 
import Compressor from 'compressorjs';
import config from '../../config';
import useUpdateReportProgress from '../../hooks/useUpdateReportProgress';
import EditImageModal from '../../components/EditImageModal';
import { useToast } from '../../contexts/ToastContext'; // Importer useToast hooken
import OppdragInfoBanner from '../../components/OppdragInfoBanner'; // Importer komponentens

const Photos = () => {
  const navigate = useNavigate();
  const { behandlingsadrId, oppdrag_id } = useParams();
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false); 
  const [imageToEdit, setImageToEdit] = useState(null); 
  const [editIndex, setEditIndex] = useState(null); // Legger til en index for å identifisere hvilket bilde som redigeres
  const fileInputRef = useRef(null);
  const token = localStorage.getItem('token');
  const { updateOppdragProgress } = useUpdateReportProgress();
  const notify = useToast(); // Bruk useToast hooken

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getOppdrag_bilde_id/${oppdrag_id}/?token=${token}`);
        
        if (response.status !== 200) {
          throw new Error('Failed to fetch image IDs');
        }
  
        const oppdragBildeIds = response.data.data.map(item => item.oppdrag_bilde_id);
        
        if (oppdragBildeIds.length === 0) {
          setImages([]);
          return;
        }
  
        const imagePromises = oppdragBildeIds.map(async (id) => {
          const imageResponse = await apiClient.get(`${config.apiUrl}/getPhotosOppdrag/${id}/?token=${token}`);
          const commentResponse = await apiClient.get(`${config.apiUrl}/getPhotosOppdragComment/${id}/?token=${token}`);
          
          if (imageResponse.status !== 200 || commentResponse.status !== 200) {
            throw new Error('Failed to fetch image or comment');
          }
  
          return {
            id,
            src: imageResponse.data.data.bilde,
            comment: commentResponse.data.data.kommentar || ''
          };
        });
  
        const fetchedImages = await Promise.all(imagePromises);
        setImages(fetchedImages);
      } catch (error) {
        console.error('Error fetching images:', error);
        
        if (error.response) {
          notify('error', `Failed to fetch images: ${error.response.statusText}`);
        } else if (error.request) {
          notify('error', 'Failed to fetch images due to network error. Please check your connection.');
        } else {
          notify('error', 'Failed to fetch images. Please try again.');
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchImages();
  }, [oppdrag_id, token, notify]);

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + images.length + newImages.length > 10) {
      notify('error', 'You can only upload up to 10 images.');
      return;
    }
    const newImagesPromises = files.map((file) => new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 1000,
        maxHeight: 1000,
        success: (compressedResult) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({ src: reader.result, file: compressedResult, comment: '' });
          };
          reader.readAsDataURL(compressedResult);
        },
        error: (err) => {
          console.error('Error compressing file:', err);
          notify('error', 'Failed to compress photo. Please try again.');
          reject(err);
        }
      });
    }));
    Promise.all(newImagesPromises).then((newImagesResults) => {
      setNewImages((prevNewImages) => [...prevNewImages, ...newImagesResults]);
    });
  };

  const handleCommentChange = (e, index, isNewImage) => {
    const { value } = e.target;
    if (isNewImage) {
      const updatedNewImages = [...newImages];
      updatedNewImages[index].comment = value;
      setNewImages(updatedNewImages);
    } else {
      const updatedImages = [...images];
      updatedImages[index].comment = value;
      setImages(updatedImages);
    }
  };

  const handleDelete = (index, isNewImage) => {
    if (isNewImage) {
      const updatedNewImages = [...newImages];
      updatedNewImages.splice(index, 1);
      setNewImages(updatedNewImages);
    } else {
      const updatedImages = [...images];
      const [deletedImage] = updatedImages.splice(index, 1);
      apiClient.delete(`${config.apiUrl}/delPhotosOppdrag/?token=${token}`, {
        data: { oppdrag_bilde_id: deletedImage.id }
      }).then(() => {
        notify('success', 'Image deleted successfully.');
      }).catch((error) => {
        console.error('Error deleting image:', error);
        notify('error', 'Failed to delete image. Please try again.');
      });
      setImages(updatedImages);
    }
  };

  const handleSave = async () => {
    try {
      const uploadPromises = newImages.map(async (image) => {
        const response = await apiClient.post(`${config.apiUrl}/addReportPhotos/?token=${token}`, {
          bilde: image.src,
          kommentar: image.comment,
          oppdrag_id
        });
        const oppdrag_bilde_id = response.data.oppdrag_bilde_id;
        return { ...image, id: oppdrag_bilde_id };
      });
      const uploadedImages = await Promise.all(uploadPromises);

      setImages((prevImages) => [...prevImages, ...uploadedImages]);
      setNewImages([]);

      const success = await updateOppdragProgress(token, oppdrag_id, behandlingsadrId, `/legacy/preview/${behandlingsadrId}/${oppdrag_id}`, navigate);

      if (success) {
        notify('success', 'Photos saved successfully.');
        notify('dismiss'); // Unmount any active toasts
        navigate(`/legacy/preview/${behandlingsadrId}/${oppdrag_id}`);
      }
    } catch (error) {
      console.error('Error saving photos:', error);
      notify('error', 'Failed to save photos. Please try again.');
    }
  };

  const handleBack = () => {
    navigate(`/legacy/comment/${behandlingsadrId}/${oppdrag_id}`);
  };

  const handleEditImage = (image, index, isNewImage) => {
    setImageToEdit(image);
    setEditIndex({ index, isNewImage }); // Setter indexen til bildet som skal redigeres
    setShowEditModal(true);
  };

  const handleSaveImage = (editedImage) => {
    if (editIndex.isNewImage) {
      const updatedNewImages = [...newImages];
      updatedNewImages[editIndex.index].src = editedImage;
      setNewImages(updatedNewImages);
    } else {
      const updatedImages = [...images];
      updatedImages[editIndex.index].src = editedImage;
      setImages(updatedImages);
    }
    setShowEditModal(false);
  };

  return (
    <MainLayout title="Photos">
      <div className="cr-container">
        <OppdragInfoBanner /> {/* Legg til banneret her */}
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <h2 className="cr-heading">You can add up to 10 additional photos with comments:</h2>
            <div className="cr-box">
              <div className="cr-image-list">
                {images.map((image, index) => (
                  <div key={index} className="cr-image-item">
                    <img src={image.src} alt="uploaded" className="cr-preview-image" />
                    <div className="cr-buttons">
                      <button className="cr-button cr-delete-button" onClick={() => handleDelete(index, false)}>Delete</button>
                      <button
                        className="cr-button cr-edit-button"
                        onClick={() => handleEditImage(image, index, false)}
                      >
                        Edit Image
                      </button>
                    </div>
                    <textarea
                      className="cr-textarea"
                      placeholder="Comment"
                      value={image.comment}
                      onChange={(e) => handleCommentChange(e, index, false)}
                    />
                  </div>
                ))}
                {newImages.map((image, index) => (
                  <div key={index + images.length} className="cr-image-item">
                    <img src={image.src} alt="new upload" className="cr-preview-image" />
                    <div className="cr-buttons">
                      <button className="cr-button cr-delete-button" onClick={() => handleDelete(index, true)}>Delete</button>
                      <button
                        className="cr-button cr-edit-button"
                        onClick={() => handleEditImage(image, index, true)}
                      >
                        Edit Image
                      </button>
                    </div>
                    <textarea
                      className="cr-textarea"
                      placeholder="Comment"
                      value={image.comment}
                      onChange={(e) => handleCommentChange(e, index, true)}
                    />
                  </div>
                ))}
              </div>
              <div className="cr-image-placeholder" onClick={handleFileClick}>
                <FaCamera className="cr-camera-icon" />
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
              </div>
            </div>
            <div className="cr-buttons">
              <button className="cr-button cr-back-button" onClick={handleBack}>Back</button>
              <button className="cr-button cr-save-button" onClick={handleSave}>Save</button>
            </div>
          </>
        )}
      </div>
      {imageToEdit && (
        <EditImageModal
          show={showEditModal}
          onClose={() => setShowEditModal(false)}
          imageUrl={imageToEdit.src}
          onSaveImage={handleSaveImage}
        />
      )}
    </MainLayout>
  );
};

export default Photos;
