import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import { FaRoute } from 'react-icons/fa';
import { LanguageContext } from '../contexts/LanguageContext';
import usePreviousPage from '../hooks/usePreviousPage';
import { useToast } from '../contexts/ToastContext';
import ServiceAddressCheckpoint from '../components/ServiceAddressCheckpoint';
import ServiceAddressFloorplan from '../components/ServiceAddressFloorplan';
import ServiceAddressVisitHistory from '../components/ServiceAddressVisitHistory';
import ServiceAddressPlannedWork from '../components/ServiceAddressPlannedWork';
import ServiceAddressScheduleModal from '../components/ServiceAddressScheduleModal';
import ServiceAddressUpdateInfo from '../components/ServiceAddressUpdateInfo';
import './ServiceAddress.css';
import config from '../config';

const ServiceAddress = () => {
  const { behandlingsadrId } = useParams();
  const navigate = useNavigate();
  const { translations } = useContext(LanguageContext);
  const notify = useToast();
  const [addressData, setAddressData] = useState(null);
  const [visitCount, setVisitCount] = useState(0);
  const [nextVisit, setNextVisit] = useState(null);
  const [futureAssignments, setFutureAssignments] = useState([]);
  const [visitHistory, setVisitHistory] = useState([]);
  const [pests, setPests] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [isCheckpointsVisible, setIsCheckpointsVisible] = useState(false);
  const [floorPlans, setFloorPlans] = useState([]);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isUpdateInfoModalOpen, setIsUpdateInfoModalOpen] = useState(false);
  

  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userid');
  const { previousPage } = usePreviousPage();


  const fetchServiceCardData = useCallback(async (behandlingsadrId) => {
    try {
      const addressResponse = await apiClient.get(`${config.apiUrl}/getServiceCardCust/${behandlingsadrId}?token=${token}`);
      const avtale_id = addressResponse.data.data[0].avtale_id;

      const [
        visitCountResponse,
        nextVisitResponse,
        futureAssignmentsResponse,
        visitHistoryResponse,
        pestsResponse,
        equipmentResponse,
        floorPlansResponse
      ] = await Promise.all([
        apiClient.get(`${config.apiUrl}/getCountVisitCurrentYear/${behandlingsadrId}?token=${token}`),
        apiClient.get(`${config.apiUrl}/getServiceCardNextVisit/${behandlingsadrId}?token=${token}`),
        apiClient.get(`${config.apiUrl}/getFutureAssignmentServiceCard/${behandlingsadrId}?token=${token}`),
        apiClient.get(`${config.apiUrl}/getServicecardAssignmnt/${behandlingsadrId}?token=${token}`),
        apiClient.get(`${config.apiUrl}/getBehandling/${avtale_id}?token=${token}`),
        apiClient.get(`${config.apiUrl}/getUtstyrInk/${avtale_id}?token=${token}`),
        apiClient.get(`${config.apiUrl}/getFloorplans/${avtale_id}?token=${token}`)
      ]);

      setAddressData(addressResponse.data.data[0]);
      setVisitCount(visitCountResponse.data.data[0].count);
      setNextVisit(nextVisitResponse.data.data[0]);
      setFutureAssignments(futureAssignmentsResponse.data.data);
      setVisitHistory(visitHistoryResponse.data.data);
      setPests(pestsResponse.data.data);
      setEquipment(equipmentResponse.data.data);
      setFloorPlans(floorPlansResponse.data.data);
    } catch (error) {
      console.error('Error fetching service card data:', error);
      notify('error', 'Failed to fetch service card data.');
    }
  }, [token, notify]);

  useEffect(() => {
    if (!behandlingsadrId) {
      navigate('/search-address');
    } else {
      fetchServiceCardData(behandlingsadrId);
    }
  }, [behandlingsadrId, navigate, fetchServiceCardData]);

   // Lag `customerAddress` etter at `addressData` er satt
   const customerAddress = addressData ? `${addressData.behandlingsadr}, ${addressData.poststed}` : '';
  
  const handleCreateReport = async () => {
    try {
      const besokResponse = await apiClient.get(`${config.apiUrl}/getServiceAddressBesokId/${behandlingsadrId}?token=${token}`);
      const type_besok_id = besokResponse.data.data[0].type_besok_id;

      if (type_besok_id === -1) {
        notify('error', 'Invalid visit ID. Cannot start report.');
        return;
      }

      const reportData = {
        behandlingsadr_id: behandlingsadrId,
        type_besok_id: type_besok_id,
        user_id: userId,
        dato_start: new Date().toISOString().split('T')[0],
        status_id: 1
      };

      const createReportResponse = await apiClient.post(`${config.apiUrl}/createReportNew/?token=${token}`, reportData);
      const { oppdrag_id } = createReportResponse.data;
      navigate(`/legacy/createreport/${behandlingsadrId}/${oppdrag_id}`);
    } catch (error) {
      console.error('Error creating report:', error);
      notify('error', 'Failed to create report. Please try again.');
    }
  };

  const handleGetDirections = () => {
    const address = `${addressData.behandlingsadr}, ${addressData.poststed}`;
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`, '_blank');
  };

  const handleDownloadReport = (oppdrag_id) => {
    const url = `${config.apiUrl}/getFileReport/${oppdrag_id}?token=${token}`;
    window.open(url, '_blank');
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('nb-NO', options);
  };

  const openScheduleModal = () => {
    setIsScheduleModalOpen(true);
  };

  const closeScheduleModal = () => {
    setIsScheduleModalOpen(false);
  };

  const openUpdateInfoModal = () => {
    setIsUpdateInfoModalOpen(true);
  };

  const closeUpdateInfoModal = () => {
    setIsUpdateInfoModalOpen(false);
  };

  const handleSaveUpdateInfo = () => {
    fetchServiceCardData(behandlingsadrId);
    setIsUpdateInfoModalOpen(false);
  };

  const handleSave = () => {
    fetchServiceCardData(behandlingsadrId);
    setIsScheduleModalOpen(false);
  };

  const handleDeletePlannedWork = () => {
    fetchServiceCardData(behandlingsadrId);
  };

  const renderNextVisit = () => {
    if (!nextVisit || !nextVisit.next_visit) {
        return (
            <div className="table-cell-right" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <button className="button schedule-button" style={{ backgroundColor: 'yellow', padding: '5px', borderRadius: '4px', maxWidth: '150px', textAlign: 'center' }} onClick={openScheduleModal}>
                    Schedule
                </button>
            </div>
        );
    }

    const today = new Date();
    const visitDate = new Date(nextVisit.next_visit);
    const isPast = visitDate < today;

    const buttonStyle = {
        backgroundColor: isPast ? 'yellow' : 'green',
        color: 'black',
        padding: '5px 10px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        maxWidth: '150px',  // Begrens bredden til knappen
        textAlign: 'center'  // Sørg for at teksten er sentrert i knappen
    };

    return (
        <div className="table-cell-right" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <button className="button schedule-button" style={buttonStyle} onClick={openScheduleModal}>
                {formatDate(nextVisit.next_visit)}
            </button>
        </div>
    );
};

  return (
    <MainLayout title="Service Address">
      <div className="service-card-container">
        {addressData ? (
          <div className="service-card">
            <div className="service-card-header">
              <div className="service-card-info">
                <h2>
                  <Link to={`/customeroverview/${addressData.kunde_id}`}>
                    {addressData.navn}
                  </Link>
                </h2>
                <p>{addressData.behandlingsadr}</p>
                <p>{addressData.postnr} {addressData.poststed}</p>
                <p><strong>Customer No:</strong> {addressData.kundenr}</p>
                <p><strong>Contact Person:</strong> {addressData.kontakt}</p>
                <p><strong>Phone:</strong> {addressData.telefon}</p>
                <p><strong>Email (Report):</strong> {addressData.mail_to}</p>
                <p><strong>Email (Report Copy):</strong> {addressData.mail_cc}</p>
                <p><strong>Other Info:</strong> {addressData.notat}</p>
                <p><strong>Route Area:</strong> {addressData.omrade}</p>
              </div>
              <div className="map-and-button">
                <iframe
                  title="Google Maps"
                  src={`https://www.google.com/maps/embed/v1/place?key=${config.googleMapsApiKey}&q=${addressData.behandlingsadr},${addressData.poststed}`}
                  className="google-map"
                  allowFullScreen
                ></iframe>
                <button className="button get-directions-button" onClick={handleGetDirections}>
                  <FaRoute style={{ marginRight: '8px' }} /> Get Directions
                </button>
              </div>
            </div>
            <button className="button edit-button" onClick={openUpdateInfoModal}>Edit Information</button>
            <div className="service-card-body">
              <div className="table-section">
                <div className="table-row">
                  <div className="table-cell"><strong>Pest/Treatment:</strong></div>
                  <div className="table-cell-right"><strong>Extra Sanitation</strong></div>
                </div>
                {pests.map((pest, index) => (
                  <div key={index} className="table-row">
                    <div className="table-cell">{pest.behandling}</div>
                    <div className="table-cell-right">{pest.extra_sanering ? <span className="green-check">&#10004;</span> : <span className="red-cross">&#10008;</span>}</div>
                  </div>
                ))}
              </div>
              <div className="table-section">
                <div className="table-row">
                  <div className="table-cell"><strong>Equipment Type:</strong></div>
                  <div className="table-cell-right"><strong>Quantity</strong></div>
                </div>
                {equipment.map((item, index) => (
                  <div key={index} className="table-row">
                    <div className="table-cell">{item.utstyr}</div>
                    <div className="table-cell-right">{item.antall}</div>
                  </div>
                ))}
              </div>
              <div className="table-section">
                <div className="table-row">
                  <div className="table-cell"><strong>Number of Visits Per Year:</strong></div>
                  <div className="table-cell-right">{addressData.kontroller}</div>
                </div>
                <div className="table-row">
                  <div className="table-cell"><strong>Number of Visits This Year:</strong></div>
                  <div className="table-cell-right">{visitCount}</div>
                </div>
                <div className="table-row">
                  <div className="table-cell"><strong>Start Date:</strong></div>
                  <div className="table-cell-right">{addressData.dato_start ? formatDate(addressData.dato_start) : 'No Start Date'}</div>
                </div>
                <div className="table-row">
                  <div className="table-cell"><strong>Next Visit:</strong></div>
                  <div className="table-cell-right" style={{ display: 'flex', alignItems: 'center' }}>
                    {renderNextVisit()}
                  </div>
                </div>
              </div>
            </div>
            <button className="button create-report-button" onClick={handleCreateReport}>Create Report</button>

            <ServiceAddressPlannedWork 
              futureAssignments={futureAssignments} 
              token={token}
              onDelete={handleDeletePlannedWork} 
            />

            <div className="expandable-section">
              <button className="expand-button" onClick={() => setIsCheckpointsVisible(!isCheckpointsVisible)}>
                Checkpoints
              </button>
              {isCheckpointsVisible && (
                <div className="expandable-content">
                  <ServiceAddressCheckpoint behandlingsadrId={behandlingsadrId} token={token} />
                </div>
              )}
            </div>

            <ServiceAddressFloorplan 
              behandlingsadrId={behandlingsadrId} 
              token={token} 
              floorPlans={floorPlans} 
              fetchServiceCardData={fetchServiceCardData} 
              customerAddress={customerAddress}
            />

            <ServiceAddressVisitHistory 
              visitHistory={visitHistory} 
              token={token} 
              handleDownloadReport={handleDownloadReport} 
            />

          </div>
        ) : (
          <p>Loading</p>
        )}
      </div>
      <ServiceAddressScheduleModal 
        isOpen={isScheduleModalOpen}
        onClose={closeScheduleModal}
        behandlingsadrId={behandlingsadrId}
        token={token}
        onSave={handleSave} 
      />
      <ServiceAddressUpdateInfo
        isOpen={isUpdateInfoModalOpen}
        onClose={closeUpdateInfoModal}
        currentInfo={addressData?.notat}
        emailReport={addressData?.mail_to}
        emailReportCopy={addressData?.mail_cc}
        behandlingsadrId={behandlingsadrId}
        token={token}
        onSave={handleSaveUpdateInfo}
      />
    </MainLayout>
  );
};

export default ServiceAddress;
