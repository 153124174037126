import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import MainLayout from '../components/MainLayout';
import config from '../config';
import { useToast } from '../contexts/ToastContext'; // Importer useToast hooken
import './AgreementNewProd.css';

const AgreementNewProd = () => {
  const navigate = useNavigate();
  const { kundeId, avtaleId, behandlingsadrId } = useParams();
  const notify = useToast(); // Bruk useToast hooken
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (behandlingsadrId) {
      sessionStorage.setItem('previousPath', `/agreement-new-treat/${kundeId}/${avtaleId}/${behandlingsadrId}`);
    } else {
      sessionStorage.setItem('previousPath', `/agreement-new-treat/${kundeId}/${avtaleId}`);
    }

    const fetchProducts = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await apiClient.get(`${config.apiUrl}/getAllInvProducts/?token=${token}`);
        if (response.data.status === 'success') {
          setAvailableProducts(response.data.data);
        } else {
          console.error('Failed to fetch products');
          notify('error', 'Failed to fetch products');
        }
      } catch (error) {
        console.error('An error occurred while fetching products:', error);
        notify('error', 'An error occurred while fetching products');
      }
    };

    const fetchSelectedProducts = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await apiClient.get(`${config.apiUrl}/getAgrmtInvProduct/${avtaleId}/?token=${token}`);
        if (response.data.status === 'success') {
          const fetchedProducts = response.data.data.map(product => ({
            ...product,
            price: parseFloat(product.price_ex_vat),
            total: parseFloat(product.tot_price_ex_vat),
            qty: parseInt(product.qty),
            discount: parseInt(product.discount),
            comment: product.comment || ''
          }));
          setSelectedProducts(fetchedProducts);
        } else {
          console.error('Failed to fetch selected products');
          notify('error', 'Failed to fetch selected products');
        }
      } catch (error) {
        console.error('An error occurred while fetching selected products:', error);
        notify('error', 'An error occurred while fetching selected products');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
    fetchSelectedProducts();
  }, [kundeId, avtaleId, behandlingsadrId, notify]);

  const handleProductSelect = (e) => {
    const productId = e.target.value;
    const product = availableProducts.find(p => p.inv_product_id === parseInt(productId, 10));
    if (product) {
      setSelectedProducts([...selectedProducts, {
        ...product,
        qty: 1,
        discount: 0,
        price: parseFloat(product.price_ex_vat),
        total: parseFloat(product.price_ex_vat),
        comment: ''
      }]);
    }
  };

  const handleProductChange = (index, key, value) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts[index][key] = value;
    updatedProducts[index].total = (updatedProducts[index].qty * updatedProducts[index].price * (1 - updatedProducts[index].discount / 100)).toFixed(2);
    setSelectedProducts(updatedProducts);
  };

  const handleRemoveProduct = async (index) => {
    const product = selectedProducts[index];
    const confirmDelete = window.confirm(`Are you sure you want to delete the product: ${product.product_name}?`);
    
    if (confirmDelete) {
      const token = localStorage.getItem('token');
      try {
        const response = await apiClient.delete(`${config.apiUrl}/delAgrmtInvProduct/${product.agrmt_inv_product_id}/?token=${token}`);
        if (response.data.status === 'success') {
          const updatedProducts = selectedProducts.filter((_, i) => i !== index);
          setSelectedProducts(updatedProducts);
          notify('success', `Product ${product.product_name} deleted successfully!`);
        } else {
          console.error('Failed to delete product:', product.product_name);
          notify('error', `Failed to delete product: ${product.product_name}`);
        }
      } catch (error) {
        console.error('An error occurred while deleting product:', error);
        notify('error', 'An error occurred while deleting the product');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    try {
      for (const product of selectedProducts) {
        const payload = {
          avtale_id: avtaleId,
          inv_product_id: product.inv_product_id,
          qty: product.qty,
          price_ex_vat: product.price,
          discount: product.discount,
          tot_price_ex_vat: product.total,
          comment: product.comment,
          product_name: product.product_name,
          product_number: product.product_number,
          vat_percent: product.vat_percent,
          vat_code: product.vat_code,
          income_account: product.income_account,
          agrmt_inv_product_id: product.agrmt_inv_product_id // include the id for update
        };

        if (product.agrmt_inv_product_id) {
          // Update existing product
          const response = await apiClient.put(`${config.apiUrl}/updAgrmtInvProduct/?token=${token}`, payload);
          if (response.data.status !== 'success') {
            console.error('Failed to update product:', product.product_name);
            notify('error', `Failed to update product: ${product.product_name}`);
          }
        } else {
          // Add new product
          const response = await apiClient.post(`${config.apiUrl}/addAgrmtInvProduct/?token=${token}`, payload);
          if (response.data.status !== 'success') {
            console.error('Failed to add product:', product.product_name);
            notify('error', `Failed to add product: ${product.product_name}`);
          }
        }
      }
      // Navigate to AgreementNewPreview with the required params
      navigate(`/agreement-new-preview/${kundeId}/${avtaleId}/${behandlingsadrId}`);
    } catch (error) {
      console.error('An error occurred while saving products:', error);
      notify('error', 'An error occurred while saving the products');
    }
  };

  const handleNavigateToTreatments = () => {
    navigate(`/agreement-new-treat/${avtaleId}/${kundeId}/${behandlingsadrId}`);
  };

  if (loading) {
    return (
      <MainLayout title="Agreement New Prod">
        <div>Loading...</div>
      </MainLayout>
    );
  }

  return (
    <MainLayout title="Agreement New Prod">
      <div className="agreement-new-prod-container">
        <form onSubmit={handleSubmit} className="agreement-new-prod-form">
          <div className="product-selection">
            <label htmlFor="productSelect">Select Product:</label>
            <select id="productSelect" onChange={handleProductSelect}>
              <option value="">Choose a product</option>
              {availableProducts.map(product => (
                <option key={product.inv_product_id} value={product.inv_product_id}>
                  {product.product_name}
                </option>
              ))}
            </select>
          </div>
          <div className="product-list">
            {selectedProducts.map((product, index) => (
              <div key={index} className="product-item">
                <h3>{product.product_name}</h3>
                <label>Quantity:
                  <input
                    type="number"
                    value={product.qty}
                    onChange={(e) => handleProductChange(index, 'qty', parseFloat(e.target.value))}
                  />
                </label>
                <label>Discount:
                  <input
                    type="number"
                    value={product.discount}
                    onChange={(e) => handleProductChange(index, 'discount', parseFloat(e.target.value))}
                  />
                </label>
                <label>Price:
                  <input
                    type="number"
                    value={product.price}
                    onChange={(e) => handleProductChange(index, 'price', parseFloat(e.target.value))}
                  />
                </label>
                <label>Comment:
                  <input
                    type="text"
                    value={product.comment}
                    onChange={(e) => handleProductChange(index, 'comment', e.target.value)}
                  />
                </label>
                <p>Total: {product.total}</p>
                <button type="button" onClick={() => handleRemoveProduct(index)}>Delete</button>
              </div>
            ))}
          </div>
          <div className="form-actions">
            <button type="button" className="treatment-button" onClick={handleNavigateToTreatments}>Treatment</button>
            <button type="submit">Save and Continue</button>
          </div>
        </form>
      </div>
    </MainLayout>
  );
};

export default AgreementNewProd;
