import React, { useState, useEffect, useCallback } from 'react';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for axios
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import MainLayout from '../components/MainLayout';
import PageHeader from '../components/PageHeader'; 
import config from '../config';
import { FaSyncAlt, FaTrashAlt } from 'react-icons/fa'; // Import icons
import Modal from 'react-modal'; // Import modal for delete confirmation
import { useToast } from '../contexts/ToastContext'; // Import useToast hook
import './IncompleteReports.css'; // Import the CSS file for styles

// Set the app element for accessibility
Modal.setAppElement('#root');

const IncompleteReports = () => {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const userId = localStorage.getItem('userid');
  const token = localStorage.getItem('token');
  const navigate = useNavigate(); // Initialize useNavigate for navigation
  const notify = useToast(); // Use the custom useToast hook for notifications

  const loadReports = useCallback(() => {
    setIsLoading(true);
    apiClient
      .get(`${config.apiUrl}/getIncompletReports/${userId}/?&token=${token}`)
      .then((response) => {
        if (response.data.status === 'success') {
          console.log('Data fetched successfully:', response.data.data); // Debug message
          setReports(response.data.data);
        } else {
          console.error('Unexpected status:', response.data.status);
          notify('error', 'Failed to fetch reports.');
        }
      })
      .catch((error) => {
        console.error('Error fetching reports:', error);
        notify('error', 'Error fetching reports.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [userId, token, notify]);

  useEffect(() => {
    if (userId && token) {
      loadReports();
    }
  }, [userId, token, loadReports]);

  const handleResume = (report) => {
    console.log('Resume report:', report); // Debug message
    const { behandlingsadr_id, oppdrag_id, progress } = report;
    if (progress) {
      navigate(progress);
    } else {
      navigate(`/legacy/CreateReport/${behandlingsadr_id}/${oppdrag_id}`);
    }
  };

  const openDeleteModal = (reportId) => {
    setReportToDelete(reportId);
    setModalIsOpen(true);
  };

  const closeDeleteModal = () => {
    setModalIsOpen(false);
    setReportToDelete(null);
    loadReports(); // Refresh the list when the modal is closed
  };

  const handleDelete = () => {
    if (!reportToDelete) return;

    apiClient.post(`${config.apiUrl}/cancelReport?token=${token}`, {
      oppdrag_id: reportToDelete,
      slettet: 1,
      status_id: 3
    })
    .then(response => {
      if (response.data.status === 'success') {
        console.log('Report deleted successfully');
        notify('success', 'Report deleted successfully');
        setReports(reports.filter(report => report.oppdrag_id !== reportToDelete));
        closeDeleteModal();
      } else {
        console.error('Failed to delete report:', response.data.message);
        notify('error', 'Failed to delete report');
      }
    })
    .catch(error => {
      console.error('Error deleting report:', error);
      notify('error', 'Error deleting report');
    });
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: '500px',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    }
  };

  // Filter reports based on search query
  const filteredReports = reports
    .filter(report => {
      const { navn, behandlingsadr } = report;
      const lowerCaseQuery = searchQuery.toLowerCase();
      return (
        (navn && navn.toLowerCase().includes(lowerCaseQuery)) ||
        (behandlingsadr && behandlingsadr.toLowerCase().includes(lowerCaseQuery))
      );
    })
    .sort((a, b) => new Date(b.dato_start) - new Date(a.dato_start)); // Sort by date, newest first

  return (
    <MainLayout title="Incomplete Reports">
      <PageHeader
        title=""
        description="On this page, you can view and resume work on reports that are in progress or interrupted."
      />
      <input
        type="text"
        placeholder="Search by name or address"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-box"
      />
      <div className="tasks-container">
        {isLoading ? (
          <p>Loading...</p>
        ) : filteredReports.length > 0 ? (
          filteredReports.map((report) => (
            <div key={report.oppdrag_id} className="task-card">
              <div className="task-header">
                <h2>{`${report.navn} - Report: ${report.oppdrag_id}`}</h2>
                <span>{formatDate(report.dato_start)}</span>
              </div>
              <div className="task-body">
                <div className="task-body-left">
                  <p><strong>{report.navn}</strong></p>
                  <p>{report.behandlingsadr}</p>
                  {report.utfort !== null && <p>{report.utfort}</p>}
                </div>
                <div className="task-footer">
                  <button className="task-footer-button resume-button" onClick={() => handleResume(report)}>
                    <FaSyncAlt /> Resume
                  </button>
                  <button className="task-footer-button delete-button" onClick={() => openDeleteModal(report.oppdrag_id)}>
                    <FaTrashAlt /> Delete
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>You have no incomplete reports.</p>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeDeleteModal}
        style={customStyles}
        contentLabel="Confirm Delete"
      >
        <h2>Confirm Delete</h2>
        <p>Are you sure you want to delete this report?</p>
        <button onClick={closeDeleteModal} className="task-footer-button resume-button">Cancel</button>
        <button onClick={handleDelete} className="task-footer-button delete-button">
          <FaTrashAlt /> Confirm Delete
        </button>
      </Modal>
    </MainLayout>
  );
};

export default IncompleteReports;
