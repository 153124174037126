import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './CreateReport.css';
import MainLayout from '../../components/MainLayout';
import apiClient from '../../api/apiClient'; // Importer vår apiClient i stedet for 
import config from '../../config';
import useUpdateReportProgress from '../../hooks/useUpdateReportProgress'; // Importer hooken
import { useToast } from '../../contexts/ToastContext'; // Importer useToast hooken
import OppdragInfoBanner from '../../components/OppdragInfoBanner'; // Importer komponenten

const PestActivity = () => {
  const navigate = useNavigate();
  const { behandlingsadrId, oppdrag_id } = useParams();
  const [skadedyrAktivitet, setSkadedyrAktivitet] = useState(null);
  const [initialSkadedyrAktivitet, setInitialSkadedyrAktivitet] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem('token');
  const { updateOppdragProgress } = useUpdateReportProgress(); // Bruk hooken
  const notify = useToast(); // Bruk useToast hooken

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getActivityOppdrag/${oppdrag_id}/?token=${token}`);
        if (response.data && response.data.data) {
          const aktivitet = response.data.data[0].skadedyr_aktivitet;
          setSkadedyrAktivitet(aktivitet ? "1" : "0");
          setInitialSkadedyrAktivitet(aktivitet ? "1" : "0");
        }
      } catch (error) {
        console.error('Error fetching pest activity:', error);
        notify('error', 'Failed to fetch pest activity. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [oppdrag_id, token, notify]);

  const handleBack = async () => {
    if (skadedyrAktivitet !== initialSkadedyrAktivitet) {
      const confirmSave = window.confirm('You have unsaved changes. Do you want to save them?');
      if (confirmSave) {
        try {
          await handleSave(false);
          navigate(`/legacy/checktype/${behandlingsadrId}/${oppdrag_id}`);
        } catch (error) {
          console.error('Error saving pest activity:', error);
          notify('error', 'Failed to save pest activity. Please try again.');
        }
      } else {
        navigate(`/legacy/checktype/${behandlingsadrId}/${oppdrag_id}`);
      }
    } else {
      navigate(`/legacy/checktype/${behandlingsadrId}/${oppdrag_id}`);
    }
  };

  const handleSave = async (navigateForward = true) => {
    if (skadedyrAktivitet === null) {
      notify('error', 'Please select whether there has been pest activity or not.');
      return;
    }

    try {
      await apiClient.post(`${config.apiUrl}/updActivity/?token=${token}`, {
        oppdrag_id,
        skadedyr_aktivitet: skadedyrAktivitet,
      });

      const success = await updateOppdragProgress(token, oppdrag_id, behandlingsadrId, `/legacy/whatwedone/${behandlingsadrId}/${oppdrag_id}`, navigate);

      if (success) {
        notify('success', 'Pest activity saved successfully.');
        if (navigateForward) {
          navigate(`/legacy/whatwedone/${behandlingsadrId}/${oppdrag_id}`);
        }
      }
    } catch (error) {
      console.error('Error saving pest activity:', error);
      notify('error', 'Failed to save pest activity. Please try again.');
    }
  };

  return (
    <MainLayout title="Pest Activity">
      <div className="cr-container">
      <OppdragInfoBanner /> {/* Legg til banneret her */}
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <div className="cr-box">
              <h2 className="cr-heading">Has there been pest activity?</h2>
              <div className="cr-radio-group">
                <label className="cr-radio-label">
                  Yes
                  <input
                    type="radio"
                    name="skadedyrAktivitet"
                    value="1"
                    checked={skadedyrAktivitet === "1"}
                    onChange={() => setSkadedyrAktivitet("1")}
                    className="cr-radio-input"
                  />
                  <span className="cr-radio-custom"></span>
                </label>
                <label className="cr-radio-label">
                  No
                  <input
                    type="radio"
                    name="skadedyrAktivitet"
                    value="0"
                    checked={skadedyrAktivitet === "0"}
                    onChange={() => setSkadedyrAktivitet("0")}
                    className="cr-radio-input"
                  />
                  <span className="cr-radio-custom"></span>
                </label>
              </div>
            </div>
            <div className="cr-buttons">
              <button className="cr-button cr-back-button" onClick={handleBack}>Back</button>
              <button className="cr-button cr-save-button" onClick={() => handleSave(true)}>Save</button>
            </div>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default PestActivity;
