import React, { useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import '../pages/ServiceAddress.css'; // Oppdatert sti til CSS-filen

const ServiceAddressVisitHistory = ({ visitHistory, token, handleDownloadReport }) => {
  const [isVisitHistoryVisible, setIsVisitHistoryVisible] = useState(false);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('nb-NO', options);
  };

  return (
    <div className="expandable-section">
      <button className="expand-button" onClick={() => setIsVisitHistoryVisible(!isVisitHistoryVisible)}>
        Visit History
      </button>
      {isVisitHistoryVisible && (
        <div className="expandable-content">
          {visitHistory.length > 0 ? (
            visitHistory.map((visit) => (
              <div key={visit.oppdrag_id} className="visit-card">
                <div className="visit-card-header">
                  <p><strong>Assignment No #{visit.oppdrag_id}</strong></p>
                  <div className="date-align-right">{formatDate(visit.dato_start)}</div>
                </div>
                <div className="visit-card-body">
                  <div>
                    <p><strong>{visit.navn}</strong></p>
                    <p>{visit.utfort}</p>
                  </div>
                  <FaFilePdf className="pdf-icon" onClick={() => handleDownloadReport(visit.oppdrag_id)} />
                </div>
              </div>
            ))
          ) : (
            <p>No Records</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ServiceAddressVisitHistory;
