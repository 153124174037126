import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Rect, Circle, Line, Text, RegularPolygon, Image as KonvaImage } from 'react-konva';
import { Modal, Button, ButtonGroup, Dropdown, Form } from 'react-bootstrap';
import {
  FaPen,
  FaSquare,
  FaCircle,
  FaPlay,
  FaFont,
  FaSlash,
  FaArrowRight,
  FaTrashAlt,
  FaEraser,
  FaPalette,
  FaBrush,
  FaSearchPlus,
  FaSearchMinus,
} from 'react-icons/fa';
import { BlockPicker } from 'react-color';
import './EditImageModal.css';

const EditImageModal = ({ show, onClose, imageUrl, onSaveImage }) => {
  const [image, setImage] = useState(null);
  const [tool, setTool] = useState('pen');
  const [drawing, setDrawing] = useState(false);
  const [elements, setElements] = useState([]);
  const [color, setColor] = useState('#000000');
  const [strokeWidth, setStrokeWidth] = useState(2);
  const [selectedText, setSelectedText] = useState(null);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [selectedElementIndex, setSelectedElementIndex] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [stageScale, setStageScale] = useState(1);
  const stageRef = useRef(null);

  // useEffect for å laste inn bildet
  useEffect(() => {
    if (imageUrl) {
      const img = new window.Image();
      img.src = imageUrl;
      img.onload = () => {
        setImage(img);
      };
    }
  }, [imageUrl]);

  // useEffect for å sette initial scale basert på bildet
  useEffect(() => {
    if (show && image) {
      const containerWidth = window.innerWidth * 0.7;
      const containerHeight = window.innerHeight * 0.6;
      const scaleX = containerWidth / image.width;
      const scaleY = containerHeight / image.height;
      const initialScale = Math.min(scaleX, scaleY);
      setStageScale(initialScale);
    }
  }, [show, image]);

  const handleZoomIn = () => {
    setStageScale(stageScale * 1.2);
  };

  const handleZoomOut = () => {
    setStageScale(stageScale / 1.2);
  };

  useEffect(() => {
    const stage = stageRef.current?.getStage();
    if (stage && show) {
      const handleMouseDown = (e) => {
        setDrawing(true);
        const scaleX = stage.scaleX();
        const scaleY = stage.scaleY();
        const pos = stage.getPointerPosition();
        const adjustedPos = {
          x: pos.x / scaleX,
          y: pos.y / scaleY,
        };
        setStartPos(adjustedPos);
        if (tool === 'pen') {
          setElements((prevElements) => [
            ...prevElements,
            { tool, points: [adjustedPos.x, adjustedPos.y], stroke: color, strokeWidth },
          ]);
        } else if (tool === 'line' || tool === 'arrow') {
          setElements((prevElements) => [
            ...prevElements,
            { tool, points: [adjustedPos.x, adjustedPos.y, adjustedPos.x, adjustedPos.y], stroke: color, strokeWidth },
          ]);
        } else if (tool !== 'text' && tool !== 'textbox') {
          setElements((prevElements) => [
            ...prevElements,
            { tool, x: adjustedPos.x, y: adjustedPos.y, width: 0, height: 0, stroke: color, strokeWidth },
          ]);
        }
      };

      const handleMouseMove = (e) => {
        if (!drawing) return;

        const pos = stage.getPointerPosition();
        const adjustedPos = {
          x: pos.x / stage.scaleX(),
          y: pos.y / stage.scaleY(),
        };
        setElements((prevElements) => {
          const lastElement = { ...prevElements[prevElements.length - 1] };
          if (lastElement) {
            if (tool === 'pen') {
              lastElement.points = lastElement.points.concat([adjustedPos.x, adjustedPos.y]);
            } else if (tool === 'rect') {
              lastElement.width = adjustedPos.x - startPos.x;
              lastElement.height = adjustedPos.y - startPos.y;
            } else if (tool === 'circle') {
              const radius = Math.sqrt(
                Math.pow(adjustedPos.x - startPos.x, 2) + Math.pow(adjustedPos.y - startPos.y, 2)
              );
              lastElement.radius = radius;
            } else if (tool === 'triangle') {
              lastElement.width = adjustedPos.x - startPos.x;
              lastElement.height = adjustedPos.y - startPos.y;
            } else if ((tool === 'line' || tool === 'arrow') && lastElement.points) {
              lastElement.points[2] = adjustedPos.x;
              lastElement.points[3] = adjustedPos.y;
            }
            return [...prevElements.slice(0, prevElements.length - 1), lastElement];
          }
          return prevElements;
        });
      };

      const handleMouseUp = () => {
        setDrawing(false);
      };

      stage.on('mousedown touchstart', handleMouseDown);
      stage.on('mousemove touchmove', handleMouseMove);
      stage.on('mouseup touchend', handleMouseUp);

      // Cleanup event listeners når modalen lukkes eller komponenten avmonteres
      return () => {
        stage.off('mousedown touchstart', handleMouseDown);
        stage.off('mousemove touchmove', handleMouseMove);
        stage.off('mouseup touchend', handleMouseUp);
      };
    }
  }, [show, stageRef, tool, color, strokeWidth, drawing, elements]);

  const handleSave = () => {
    const stage = stageRef.current.getStage();
    const layer = stage.getLayers()[0];

    const imageNode = layer.findOne('Image');
    if (imageNode) {
      const scaleX = stage.scaleX();
      const scaleY = stage.scaleY();
      const width = imageNode.width() * scaleX;
      const height = imageNode.height() * scaleY;

      const croppedDataURL = stage.toDataURL({
        x: imageNode.x(),
        y: imageNode.y(),
        width: width,
        height: height,
        scale: { x: scaleX, y: scaleY },
      });

      onSaveImage(croppedDataURL);
    } else {
      const dataURL = stage.toDataURL();
      onSaveImage(dataURL);
    }

    onClose();
  };

  const handleTextChange = (e) => {
    const text = e.target.value;
    const newElements = elements.map((el) => {
      if (el === selectedText) {
        return { ...el, text };
      }
      return el;
    });
    setElements(newElements);
  };

  const handleTextDblClick = (textElement) => {
    setSelectedText(textElement);
  };

  const handleDragMove = (e, index) => {
    const newPos = e.target.position();
    const newElements = elements.map((el, i) => {
      if (i === index) {
        return { ...el, x: newPos.x, y: newPos.y };
      }
      return el;
    });
    setElements(newElements);
  };

  const handleElementClick = (index) => {
    setSelectedElementIndex(index);
  };

  const handleDeleteElement = () => {
    if (selectedElementIndex !== null) {
      const newElements = elements.filter((_, i) => i !== selectedElementIndex);
      setElements(newElements);
      setSelectedElementIndex(null);
    }
  };

  const handleClearAll = () => {
    setElements([]);
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
    setShowColorPicker(false);
  };

  return (
    <Modal show={show} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton={false}>
        <Modal.Title>Edit Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="editor-container">
          <div className="tool-panel">
            <ButtonGroup vertical>
              <Button variant={tool === 'pen' ? 'primary' : 'light'} onClick={() => setTool('pen')} title="Pen">
                <FaPen />
              </Button>
              <Button variant={tool === 'rect' ? 'primary' : 'light'} onClick={() => setTool('rect')} title="Rectangle">
                <FaSquare />
              </Button>
              <Button variant={tool === 'circle' ? 'primary' : 'light'} onClick={() => setTool('circle')} title="Circle">
                <FaCircle />
              </Button>
              <Button variant={tool === 'triangle' ? 'primary' : 'light'} onClick={() => setTool('triangle')} title="Triangle">
                <FaPlay />
              </Button>
              <Button variant={tool === 'line' ? 'primary' : 'light'} onClick={() => setTool('line')} title="Line">
                <FaSlash />
              </Button>
              <Button variant={tool === 'arrow' ? 'primary' : 'light'} onClick={() => setTool('arrow')} title="Arrow">
                <FaArrowRight />
              </Button>
              <Button variant={tool === 'text' ? 'primary' : 'light'} onClick={() => setTool('text')} title="Text">
                <FaFont />
              </Button>
              <Button variant={tool === 'textbox' ? 'primary' : 'light'} onClick={() => setTool('textbox')} title="Textbox">
                <FaFont />
              </Button>
              <Button variant="light" onClick={handleZoomIn} title="Zoom In">
                <FaSearchPlus />
              </Button>
              <Button variant="light" onClick={handleZoomOut} title="Zoom Out">
                <FaSearchMinus />
              </Button>
              <Dropdown drop="up" onToggle={() => setShowColorPicker(!showColorPicker)}>
                <Dropdown.Toggle variant="light" id="dropdown-basic" title="Color">
                  <FaPalette />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {showColorPicker && <BlockPicker color={color} onChangeComplete={handleColorChange} />}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown drop="up">
                <Dropdown.Toggle variant="light" id="dropdown-stroke" title="Stroke Width">
                  <FaBrush />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="stroke-width-picker">
                    <input
                      type="range"
                      min="1"
                      max="10"
                      value={strokeWidth}
                      onChange={(e) => setStrokeWidth(Number(e.target.value))}
                    />
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <Button variant="danger" onClick={handleDeleteElement} disabled={selectedElementIndex === null} title="Delete Selected">
                <FaTrashAlt />
              </Button>
              <Button variant="danger" onClick={handleClearAll} title="Clear All">
                <FaEraser />
              </Button>
            </ButtonGroup>
          </div>
          <Stage
            ref={stageRef}
            width={window.innerWidth * 0.7}
            height={window.innerHeight * 0.6}
            className="canvas-stage"
            scaleX={stageScale}
            scaleY={stageScale}
          >
            <Layer>
              {image && <KonvaImage image={image} />}
              {elements.map((el, i) => {
                const isSelected = i === selectedElementIndex;
                const commonProps = {
                  stroke: el.stroke,
                  strokeWidth: el.strokeWidth,
                  draggable: true,
                  onDragMove: (e) => handleDragMove(e, i),
                  onClick: () => handleElementClick(i),
                };

                if (el.tool === 'pen') {
                  return (
                    <Line
                      key={i}
                      {...commonProps}
                      points={el.points || []}
                      tension={0.5}
                      lineCap="round"
                      stroke={isSelected ? 'red' : el.stroke}
                    />
                  );
                } else if (el.tool === 'rect') {
                  return (
                    <Rect
                      key={i}
                      {...commonProps}
                      x={el.x}
                      y={el.y}
                      width={el.width}
                      height={el.height}
                      fill="transparent"
                      stroke={isSelected ? 'red' : el.stroke}
                    />
                  );
                } else if (el.tool === 'circle') {
                  return (
                    <Circle
                      key={i}
                      {...commonProps}
                      x={el.x}
                      y={el.y}
                      radius={el.radius || 0}
                      fill="transparent"
                      stroke={isSelected ? 'red' : el.stroke}
                    />
                  );
                } else if (el.tool === 'triangle') {
                  return (
                    <RegularPolygon
                      key={i}
                      {...commonProps}
                      x={el.x}
                      y={el.y}
                      sides={3}
                      radius={Math.sqrt(Math.pow(el.width, 2) + Math.pow(el.height, 2)) / 2}
                      fill="transparent"
                      stroke={isSelected ? 'red' : el.stroke}
                    />
                  );
                } else if (el.tool === 'line' && el.points && el.points.length >= 4) {
                  return (
                    <Line
                      key={i}
                      {...commonProps}
                      points={el.points}
                      stroke={isSelected ? 'red' : el.stroke}
                    />
                  );
                } else if (el.tool === 'arrow' && el.points && el.points.length >= 4) {
                  return (
                    <>
                      <Line
                        key={`line-${i}`}
                        {...commonProps}
                        points={el.points}
                        stroke={isSelected ? 'red' : el.stroke}
                      />
                      <RegularPolygon
                        key={`arrow-${i}`}
                        x={el.points[2]}
                        y={el.points[3]}
                        sides={3}
                        radius={10}
                        fill={isSelected ? 'red' : el.stroke}
                        rotation={Math.atan2(
                          el.points[3] - el.points[1],
                          el.points[2] - el.points[0]
                        ) * (180 / Math.PI) + 90}
                      />
                    </>
                  );
                } else if (el.tool === 'text') {
                  return (
                    <Text
                      key={i}
                      {...commonProps}
                      x={el.x}
                      y={el.y}
                      text={el.text}
                      fontSize={el.fontSize}
                      fill={el.fill}
                      strokeWidth={0}
                      onClick={() => handleElementClick(i)}
                    />
                  );
                } else if (el.tool === 'textbox') {
                  return (
                    <Rect
                      key={i}
                      {...commonProps}
                      x={el.x}
                      y={el.y}
                      width={el.width}
                      height={el.height}
                      fill="white"
                      stroke={isSelected ? 'red' : el.stroke}
                    />
                  );
                }
                return null;
              })}
            </Layer>
          </Stage>
        </div>
        {selectedText && (
          <Form.Group controlId="formTextInput" className="text-input">
            <Form.Control
              type="text"
              value={selectedText.text}
              onChange={handleTextChange}
              placeholder="Edit text"
            />
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} className="cancel-button">
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave} className="save-button">
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditImageModal;
