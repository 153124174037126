import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import { useToast } from '../contexts/ToastContext'; // Importer useToast hooken
import config from '../config';
import './AgreementNew.css';
import { LanguageContext } from '../contexts/LanguageContext'; // Import LanguageContext

const AgreementNew = () => {
  const { translations } = React.useContext(LanguageContext); // Use translations from LanguageContext
  const { kundeId, avtaleId, behandlingsadrId } = useParams();  // Read behandlingsadrId from URL
  const navigate = useNavigate();
  const location = useLocation();
  const notify = useToast(); // Bruk useToast hooken
  const [formData, setFormData] = useState({
    agreementType: '',
    startDate: '',
    annualChecks: 1,
    workArea: '',
    duration: 1,
    invoiceFormat: '',
    customerReference: '',
    ourReference: '',
    invoiceFrequency: '',
    agreementName: '',
    workDescription: '',
    additionalInformation: '',
    terms: '',
    notes: '',
    useCustomerAddress: false,
  });

  const [dropdownData, setDropdownData] = useState({
    agreementTypes: [],
    workAreas: [],
    invoiceFrequencies: [],
    invoiceFormats: [],
  });

  useEffect(() => {
    const token = localStorage.getItem('token');

    const fetchAgreementTypes = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getAgreementType?token=${token}`);
        if (response.data.status === 'success' && response.data.data) {
          setDropdownData(prevData => ({
            ...prevData,
            agreementTypes: response.data.data
          }));
        }
      } catch (error) {
        console.error('Error fetching agreement types:', error);
      }
    };

    const fetchWorkAreas = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getWorkArea?token=${token}`);
        if (response.data.status === 'success' && response.data.data) {
          setDropdownData(prevData => ({
            ...prevData,
            workAreas: response.data.data
          }));
        }
      } catch (error) {
        console.error('Error fetching work areas:', error);
      }
    };

    const fetchInvoiceFrequencies = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getInvFreq?token=${token}`);
        if (response.data.status === 'success' && response.data.data) {
          setDropdownData(prevData => ({
            ...prevData,
            invoiceFrequencies: response.data.data
          }));
        }
      } catch (error) {
        console.error('Error fetching invoice frequencies:', error);
      }
    };

    const fetchInvoiceFormats = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getInvType?token=${token}`);
        if (response.data.status === 'success' && response.data.data) {
          setDropdownData(prevData => ({
            ...prevData,
            invoiceFormats: response.data.data
          }));
        }
      } catch (error) {
        console.error('Error fetching invoice formats:', error);
      }
    };

    fetchAgreementTypes();
    fetchWorkAreas();
    fetchInvoiceFrequencies();
    fetchInvoiceFormats();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');

    const fetchAgreementDetails = async () => {
      if (avtaleId) {
        try {
          const response = await apiClient.get(`${config.apiUrl}/getAgreement/${avtaleId}?token=${token}`);
          if (response.data.status === 'success' && response.data.data.length > 0) {
            const agreement = response.data.data[0];
            setFormData(prevData => ({
              ...prevData,
              agreementType: dropdownData.agreementTypes.find(type => type.avtaletype_id === agreement.avtaletype_id)?.avtaletype || '',
              startDate: agreement.dato_start,
              annualChecks: agreement.kontroller,
              workArea: dropdownData.workAreas.find(area => area.work_area_id === agreement.work_area_id)?.work_area || '',
              duration: agreement.varighet,
              invoiceFormat: dropdownData.invoiceFormats.find(format => format.inv_type_id === agreement.inv_type_id)?.inv_type || '',
              customerReference: agreement.ref_customer,
              ourReference: agreement.ref_vendor,
              invoiceFrequency: dropdownData.invoiceFrequencies.find(freq => freq.inv_freq_id === agreement.inv_freq_id)?.inv_freq || '',
              agreementName: agreement.agrmt_name,
              workDescription: agreement.work_descr,
              additionalInformation: agreement.other_info,
              terms: agreement.tac,
              notes: agreement.notat,
              useCustomerAddress: false,
            }));
          }
        } catch (error) {
          console.error('Error fetching agreement details:', error);
        }
      }
    };

    if (dropdownData.agreementTypes.length > 0 &&
        dropdownData.workAreas.length > 0 &&
        dropdownData.invoiceFrequencies.length > 0 &&
        dropdownData.invoiceFormats.length > 0) {
      fetchAgreementDetails();
    }
  }, [avtaleId, dropdownData]);

  const fetchAdditionalInfoAndTerms = (agreementTypeId) => {
    const token = localStorage.getItem('token');
    const additionalInfoRoute = agreementTypeId === 1 ? 'GetOtherInfoJob' : 'GetOtherInfoService';
    const termsRoute = agreementTypeId === 1 ? 'GetTACJob' : 'GetTACService';

    apiClient.get(`${config.apiUrl}/${additionalInfoRoute}?token=${token}`)
      .then(response => {
        if (response.data.status === 'success' && response.data.data && response.data.data[0]) {
          setFormData(prevData => ({
            ...prevData,
            additionalInformation: response.data.data[0].value
          }));
        }
      })
      .catch(error => console.error(`Error fetching additional information from ${additionalInfoRoute}:`, error));

    apiClient.get(`${config.apiUrl}/${termsRoute}?token=${token}`)
      .then(response => {
        if (response.data.status === 'success' && response.data.data && response.data.data[0]) {
          setFormData(prevData => ({
            ...prevData,
            terms: response.data.data[0].value
          }));
        }
      })
      .catch(error => console.error(`Error fetching terms from ${termsRoute}:`, error));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });

    if (name === 'agreementType') {
      const selectedAgreementType = dropdownData.agreementTypes.find(type => type.avtaletype === value);
      if (selectedAgreementType) {
        fetchAdditionalInfoAndTerms(selectedAgreementType.avtaletype_id);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const payload = {
      agrmt_name: formData.agreementName,
      avtale_id: avtaleId || "",
      avtaletype_id: dropdownData.agreementTypes.find(type => type.avtaletype === formData.agreementType).avtaletype_id,
      dato_start: formData.startDate,
      inv_freq_id: dropdownData.invoiceFrequencies.find(freq => freq.inv_freq === formData.invoiceFrequency).inv_freq_id,
      inv_type_id: dropdownData.invoiceFormats.find(format => format.inv_type === formData.invoiceFormat).inv_type_id,
      kontroller: formData.annualChecks,
      kunde_id: parseInt(kundeId, 10),
      notat: formData.notes,
      other_info: formData.additionalInformation,
      ref_customer: formData.customerReference,
      ref_vendor: formData.ourReference,
      tac: formData.terms,
      varighet: formData.duration,
      work_area_id: dropdownData.workAreas.find(area => area.work_area === formData.workArea).work_area_id,
      work_descr: formData.workDescription
    };

    try {
      const response = await apiClient({
        method: avtaleId ? 'put' : 'post',
        url: `${config.apiUrl}/${avtaleId ? 'updAgreement' : 'addAgreement'}?token=${token}`,
        data: payload
      });

      if (response.data.status === 'success') {
        const newAvtaleId = response.data.avtale_id || avtaleId;
        notify('success', `Agreement ${avtaleId ? 'updated' : 'saved'} successfully! Agreement ID: ${newAvtaleId}`);

        const nextPath = behandlingsadrId 
          ? `/serviceaddress-new/${kundeId}/${newAvtaleId}/${behandlingsadrId}` 
          : `/serviceaddress-new/${kundeId}/${newAvtaleId}`;

        navigate(nextPath);
      } else {
        notify('error', 'Failed to save agreement.');
      }
    } catch (error) {
      notify('error', 'An error occurred while saving the agreement.');
    }
  };

  const handleCancel = () => {
    navigate(-1); 
  };

  return (
    <MainLayout title={translations['newAgreement'] || "New Agreement"}>
      <div className="agreement-new-container">
        <h1>{translations['newAgreementTitle'] || ""}</h1>
        <form className="agreement-new-form" onSubmit={handleSubmit}>
          <div className="inline-group">
            <div className="agreement-new-form-group">
              <label>{translations['agreementType'] || 'Agreement Type'}</label>
              <select
                name="agreementType"
                value={formData.agreementType}
                onChange={handleChange}
                className="agreement-input-field mandatory"
                required
              >
                <option value="">{translations['chooseAgreementType'] || 'Choose Agreement Type'}</option>
                {dropdownData.agreementTypes.map(type => (
                  <option key={type.avtaletype_id} value={type.avtaletype}>{type.avtaletype}</option>
                ))}
              </select>
            </div>
            <div className="agreement-new-form-group">
              <label>{translations['startDate'] || 'Start Date'}</label>
              <input
                type="date"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                className="agreement-input-field mandatory"
                required
              />
            </div>
          </div>
          <div className="inline-group">
            <div className="agreement-new-form-group">
              <label>{translations['annualChecks'] || 'Annual Checks'}</label>
              <select
                name="annualChecks"
                value={formData.annualChecks}
                onChange={handleChange}
                className="agreement-input-field"
              >
                {[...Array(12)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>{i + 1}</option>
                ))}
              </select>
            </div>
            <div className="agreement-new-form-group">
              <label>{translations['duration'] || 'Duration'}</label>
              <select
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                className="agreement-input-field"
              >
                {[...Array(10)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>{i + 1}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="inline-group">
            <div className="agreement-new-form-group">
              <label>{translations['workArea'] || 'Work Area'}</label>
              <select
                name="workArea"
                value={formData.workArea}
                onChange={handleChange}
                className="agreement-input-field mandatory"
                required
              >
                <option value="">{translations['chooseWorkArea'] || 'Choose Work Area'}</option>
                {dropdownData.workAreas.map(area => (
                  <option key={area.work_area_id} value={area.work_area}>{area.work_area}</option>
                ))}
              </select>
            </div>
            <div className="agreement-new-form-group">
              <label>{translations['invoiceFormat'] || 'Invoice Format'}</label>
              <select
                name="invoiceFormat"
                value={formData.invoiceFormat}
                onChange={handleChange}
                className="agreement-input-field mandatory"
                required
              >
                <option value="">{translations['chooseInvoiceFormat'] || 'Choose Invoice Format'}</option>
                {dropdownData.invoiceFormats.map(format => (
                  <option key={format.inv_type_id} value={format.inv_type}>{format.inv_type}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="inline-group">
            <div className="agreement-new-form-group">
              <label>{translations['customerReference'] || 'Customer Reference'}</label>
              <input
                type="text"
                name="customerReference"
                value={formData.customerReference}
                onChange={handleChange}
                placeholder={translations['enterCustomerReference'] || 'Enter customers reference (optional)'}
                className="agreement-input-field"
              />
            </div>
            <div className="agreement-new-form-group">
              <label>{translations['ourReference'] || 'Our Reference'}</label>
              <input
                type="text"
                name="ourReference"
                value={formData.ourReference}
                onChange={handleChange}
                placeholder={translations['enterOurReference'] || 'Enter our reference (Optional)'}
                className="agreement-input-field"
              />
            </div>
          </div>
          <div className="inline-group">
            <div className="agreement-new-form-group">
              <label>{translations['invoiceFrequency'] || 'Invoice Frequency'}</label>
              <select
                name="invoiceFrequency"
                value={formData.invoiceFrequency}
                onChange={handleChange}
                className="agreement-input-field mandatory"
                required
              >
                <option value="">{translations['chooseInvoiceFrequency'] || 'Choose Invoice Frequency'}</option>
                {dropdownData.invoiceFrequencies.map(frequency => (
                  <option key={frequency.inv_freq_id} value={frequency.inv_freq}>{frequency.inv_freq}</option>
                ))}
              </select>
            </div>
            <div className="agreement-new-form-group">
              <label>{translations['agreementName'] || 'Agreement Name'}</label>
              <input
                type="text"
                name="agreementName"
                value={formData.agreementName}
                onChange={handleChange}
                placeholder={translations['enterAgreementName'] || 'Enter agreement name (optional)'}
                className="agreement-input-field"
              />
            </div>
          </div>
          <div className="agreement-new-form-group">
            <label>{translations['workDescription'] || 'Work Description'}</label>
            <textarea
              name="workDescription"
              value={formData.workDescription}
              onChange={handleChange}
              className="agreement-input-field"
              rows="4"
              placeholder={translations['enterWorkDescription'] || 'Enter job description (optional)'}
            ></textarea>
          </div>
          <div className="agreement-new-form-group">
            <label>{translations['additionalInformation'] || 'Additional Information'}</label>
            <textarea
              name="additionalInformation"
              value={formData.additionalInformation}
              onChange={handleChange}
              className="agreement-input-field"
              rows="4"
              placeholder={translations['enterAdditionalInformation'] || 'Enter additional Information (optional)'}
            ></textarea>
          </div>
          <div className="agreement-new-form-group">
            <label>{translations['terms'] || 'Terms & Conditions'}</label>
            <textarea
              name="terms"
              value={formData.terms}
              onChange={handleChange}
              className="agreement-input-field"
              rows="4"
              placeholder={translations['enterTerms'] || 'Enter Terms & Conditions (optional)'}
            ></textarea>
          </div>
          <div className="agreement-new-form-group">
            <label>{translations['notes'] || 'Notes (Internal only)'}</label>
            <textarea
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              className="agreement-input-field"
              rows="4"
              placeholder={translations['enterInternalNotes'] || 'Enter any internal additional notes, not visible to the customer (optional)'}
            ></textarea>
          </div>
          <div className="agreement-new-button-group">
            <button type="button" className="agreement-cancel-button" onClick={handleCancel}>
              {translations['cancel'] || 'Cancel'}
            </button>
            <button type="submit" className="agreement-save-button" disabled={!formData.agreementType || !formData.startDate || !formData.workArea || !formData.invoiceFrequency}>
              {avtaleId ? (translations['update'] || 'Update') : (translations['next'] || 'Next')}
            </button>
          </div>
        </form>
      </div>
    </MainLayout>
  );
};

export default AgreementNew;
