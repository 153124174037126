import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './CreateReport.css';
import MainLayout from '../../components/MainLayout';
import apiClient from '../../api/apiClient'; // Importer vår apiClient i stedet for 
import config from '../../config';
import useUpdateReportProgress from '../../hooks/useUpdateReportProgress'; // Importer hooken
import { useToast } from '../../contexts/ToastContext'; // Importer useToast hooken
import OppdragInfoBanner from '../../components/OppdragInfoBanner'; // Importer komponenten

const VisitType = () => {
  const navigate = useNavigate();
  const { behandlingsadrId, oppdrag_id } = useParams();
  const [visitTypes, setVisitTypes] = useState([]);
  const [selectedVisitType, setSelectedVisitType] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Legg til isLoading state
  const token = localStorage.getItem('token');
  const { updateOppdragProgress } = useUpdateReportProgress(); // Bruk hooken
  const notify = useToast(); // Bruk useToast hooken

  useEffect(() => {
    const fetchVisitTypes = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getVisitType/?token=${token}`);
        setVisitTypes(response.data.data);
      } catch (error) {
        console.error('Error fetching visit types:', error.response ? error.response.data : error.message);
        notify('error', 'Failed to fetch visit types. Please try again.');
      }
    };

    const fetchSelectedVisitType = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getVisitTypeOppdrag/${oppdrag_id}?token=${token}`);
        if (response.data.data.length > 0) {
          const typeId = response.data.data[0].type_besok_id;
          if (typeId !== null) {
            setSelectedVisitType(typeId.toString());
          }
        }
      } catch (error) {
        console.error('Error fetching selected visit type:', error.response ? error.response.data : error.message);
        notify('error', 'Failed to fetch selected visit type. Please try again.');
      } finally {
        setIsLoading(false); // Sett isLoading til false når dataene er lastet inn
      }
    };

    fetchVisitTypes();
    fetchSelectedVisitType();
  }, [oppdrag_id, token, notify]);

  const handleSave = async () => {
    if (!selectedVisitType) {
      notify('error', 'Please select a visit type.');
      return;
    }
  
    try {
      await apiClient.post(`${config.apiUrl}/updVisitType/?token=${token}`, {
        oppdrag_id,
        type_besok_id: selectedVisitType
      });
  
      // Bruk hooken for å oppdatere oppdrag progress
      const success = await updateOppdragProgress(token, oppdrag_id, behandlingsadrId, `/legacy/checktype/${behandlingsadrId}/${oppdrag_id}`, navigate);
  
      if (success) {
        notify('success', 'Visit type saved successfully.');
        navigate(`/legacy/checktype/${behandlingsadrId}/${oppdrag_id}`);
      }
    } catch (error) {
      console.error('Error saving visit type:', error.response ? error.response.data : error.message);
      notify('error', 'Failed to save visit type. Please try again.');
    }
  };
  
  const handleBack = () => {
    navigate(`/legacy/createreport/${behandlingsadrId}/${oppdrag_id}`);
  };
  
  const getSelectClass = (value) => {
    return value === '' ? 'ml-mandatory' : 'ml-filled';
  };

  return (
    <MainLayout title="Visit Type">
      <div className="cr-container">
      <OppdragInfoBanner /> {/* Legg til banneret her */}
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <div className="cr-box">
            <h2 className="cr-heading">Select Visit Type:</h2>
            <div className="cr-select-container">
              <select
                className={`cr-select ${getSelectClass(selectedVisitType)}`}
                value={selectedVisitType}
                onChange={(e) => setSelectedVisitType(e.target.value)}
              >
                <option value="">Select a visit type</option>
                {visitTypes.map((type) => (
                  <option key={type.type_besok_id} value={type.type_besok_id || ''}>
                    {`${type.type_besok} (${type.type_besok_alias})`}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div className="cr-buttons">
          <button className="cr-button cr-back-button" onClick={handleBack}>Back</button>
          <button className="cr-button cr-save-button" onClick={handleSave}>Save</button>
        </div>
      </div>
    </MainLayout>
  );
};

export default VisitType;
