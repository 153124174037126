// src/pages/Calendar.js


import React from 'react';
import MainLayout from '../components/MainLayout';


const Calendar = () => {
  return (
    <MainLayout title= "Calendar"> {/* Send title prop */}
      {() => (
        <div>
          <h1></h1>
        </div>
      )}
    </MainLayout>
  );
};

export default Calendar;
