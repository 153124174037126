import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import './ServiceAddressUpdateInfo.css';
import { useToast } from '../contexts/ToastContext';
import config from '../config';

const ServiceAddressUpdateInfo = ({ isOpen, onClose, currentInfo, emailReport, emailReportCopy, behandlingsadrId, token, onSave }) => {
  const [notes, setNotes] = useState('');
  const [email, setEmail] = useState('');
  const [emailCc, setEmailCc] = useState('');

  useEffect(() => {
    if (currentInfo) setNotes(currentInfo);
    if (emailReport) setEmail(emailReport);
    if (emailReportCopy) setEmailCc(emailReportCopy);
  }, [currentInfo, emailReport, emailReportCopy]);

  const notify = useToast();

  const handleSave = async () => {
    try {
      const response = await apiClient.post(`${config.apiUrl}/updServiceAddressNew/?token=${token}`, {
        notat: notes,
        mail_to: email,
        mail_cc: emailCc,
        behandlingsadr_id: behandlingsadrId,
      });

      if (response.data.status === 'success') {
        notify('success', 'Information updated successfully');
        onSave();
        onClose();
      } else {
        notify('error', 'Failed to update information');
      }
    } catch (error) {
      console.error('Error updating information:', error);
      notify('error', 'An error occurred while updating information');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Update info Service Address"
      className="update-info-modal"
      overlayClassName="update-info-overlay"
    >
      <h2>Update Service Address Information</h2>
      <div className="input-field">
        <label htmlFor="email">Email (report)</label>
        <input
          id="email"
          name="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter report email"
          className="update-info-input"
        />
      </div>
      <div className="input-field">
        <label htmlFor="emailCc">Email (Report copy)</label>
        <input
          id="emailCc"
          name="emailCc"
          type="email"
          value={emailCc}
          onChange={(e) => setEmailCc(e.target.value)}
          placeholder="Enter report copy email"
          className="update-info-input"
        />
      </div>
      <div className="input-field">
        <label htmlFor="notes">Other Information</label>
        <textarea
          id="notes"
          name="notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Write notes about the service address here"
          className="update-info-textarea"
        />
      </div>
      <div className="update-info-buttons">
        <button className="button cancel-button" onClick={onClose}>Cancel</button>
        <button className="button save-button" onClick={handleSave}>Save</button>
      </div>
    </Modal>
  );
};

export default ServiceAddressUpdateInfo;
