import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import config from '../config';
import './CustomerOverviewEditAgrmt.css';

Modal.setAppElement('#root');

const CustomerOverviewEditAgrmt = ({ isOpen, onRequestClose, agreement, onUpdate }) => {
  const [agrmtName, setAgrmtName] = useState('');
  const [workDescr, setWorkDescr] = useState('');
  const [notes, setNotes] = useState('');

  useEffect(() => {
    if (agreement) {
      setAgrmtName(agreement.agrmt_name || '');
      setWorkDescr(agreement.work_descr || '');
      setNotes(agreement.notat || '');
    }
  }, [agreement]);

  const handleSave = async () => {
    const token = localStorage.getItem('token');
    const data = {
      notat: notes,
      work_descr: workDescr,
      avtale_id: agreement.avtale_id,
      agrmt_name: agrmtName,
      
    };

    try {
      const response = await apiClient.post(`${config.apiUrl}/updAgmtCustOverview/?token=${token}`, data);
      if (response.data.status === 'success') {
        onUpdate(); // Kaller onUpdate for å oppdatere dataene i CustomerOverview
        onRequestClose(); // Lukker modalen
      }
    } catch (error) {
      console.error('Error updating agreement:', error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="custom-modal"
      overlayClassName="custom-overlay"
    >
      <h2>Edit Agreement</h2>
      <div className="custom-input-field">
        <label>Agreement Name</label>
        <input
          type="text"
          value={agrmtName}
          onChange={(e) => setAgrmtName(e.target.value)}
        />
      </div>
      <div className="custom-input-field">
        <label>Work Description</label>
        <input
          type="text"
          value={workDescr}
          onChange={(e) => setWorkDescr(e.target.value)}
        />
      </div>
      <div className="custom-input-field">
        <label>Notes</label>
        <textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Enter notes here"
        />
      </div>
      <div className="custom-modal-buttons">
        <button className="button save-button" onClick={handleSave}>Save</button>
        <button className="button cancel-button" onClick={onRequestClose}>Cancel</button>
      </div>
    </Modal>
  );
};

export default CustomerOverviewEditAgrmt;
