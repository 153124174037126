import React from 'react';

const Filter = ({ priorityFilter, treatmentFilter, treatmentOptions, setPriorityFilter, setTreatmentFilter, handleFilterChange, disabled }) => {

  const handlePriorityChange = (e) => {
    if (disabled) return;
    const { value, checked } = e.target;
    const newPriorityFilter = checked 
      ? [...priorityFilter, parseInt(value)] 
      : priorityFilter.filter(item => item !== parseInt(value));
    setPriorityFilter(newPriorityFilter);
    handleFilterChange(newPriorityFilter, treatmentFilter);
  };

  const handleTreatmentChange = (e) => {
    if (disabled) return;
    const { value, checked } = e.target;
    const newTreatmentFilter = checked 
      ? [...treatmentFilter, parseInt(value)] 
      : treatmentFilter.filter(item => item !== parseInt(value));
    setTreatmentFilter(newTreatmentFilter);
    handleFilterChange(priorityFilter, newTreatmentFilter);
  };

  return (
    <div className={`filter-container ${disabled ? 'disabled' : ''}`}>
      <div className="filter-section">
        <h4>Priority</h4>
        <label>
          <input type="checkbox" value="1" onChange={handlePriorityChange} checked={priorityFilter.includes(1)} disabled={disabled} />
          Orange
        </label>
        <label>
          <input type="checkbox" value="2" onChange={handlePriorityChange} checked={priorityFilter.includes(2)} disabled={disabled} />
          Yellow
        </label>
        <label>
          <input type="checkbox" value="3" onChange={handlePriorityChange} checked={priorityFilter.includes(3)} disabled={disabled} />
          Green
        </label>
      </div>
      <div className="filter-section">
        <h4>Treatment</h4>
        {treatmentOptions.map(option => (
          <label key={option.behandling_id}>
            <input type="checkbox" value={option.behandling_id} onChange={handleTreatmentChange} checked={treatmentFilter.includes(option.behandling_id)} disabled={disabled} />
            {option.behandling}
          </label>
        ))}
      </div>
    </div>
  );
};

export default Filter;
