/**
 * This file handles the registration of the service worker for the React app.
 * The service worker enables offline capabilities and faster loading through caching.
 * The registration process checks if the app is running in a production environment 
 * and registers the service worker accordingly.
 */

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||  // Check if the hostname is localhost
  window.location.hostname === '[::1]' ||     // Check if the hostname is IPv6 localhost
  window.location.hostname.match(
    /^127(?:\.\d{1,3}){3}$/                   // Check if the hostname is a local IP address
  )
);

/**
 * Registers the service worker if the environment is production and service workers are supported.
 * @param {Object} config - Configuration object for the service worker.
 */
export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // Check if the service worker is valid in localhost environment
        checkValidServiceWorker(swUrl, config);

        navigator.serviceWorker.ready.then(() => {
          console.log('This web app is being served cache-first by a service worker');
        });
      } else {
        // Register the service worker in production environment
        registerValidSW(swUrl, config);
      }
    });
  }
}

/**
 * Registers a valid service worker and sets up event listeners for updates.
 * @param {string} swUrl - The URL of the service worker script.
 * @param {Object} config - Configuration object for the service worker.
 */
function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              console.log('New content is available; please refresh.');

              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              console.log('Content is cached for offline use.');

              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

/**
 * Checks if the service worker is valid and reloads the page if it is not.
 * @param {string} swUrl - The URL of the service worker script.
 * @param {Object} config - Configuration object for the service worker.
 */
function checkValidServiceWorker(swUrl, config) {
  fetch(swUrl)
    .then(response => {
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
}

/**
 * Unregisters the service worker.
 */
export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
