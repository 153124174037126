import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import './Addresses.css';
import config from '../config';
import OptimalRoute from '../components/OptimalRoute';
import Filter from '../components/Filter';
import SortSelector from '../components/SortSelector';
import AddressCard from '../components/AddressCard';
import { FaFilter } from 'react-icons/fa';

const Addresses = () => {
  const { rute_omrade_id } = useParams();
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [sortedAddresses, setSortedAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortCriteria, setSortCriteria] = useState('predefined');
  const [distances, setDistances] = useState({});
  const [priorityFilter, setPriorityFilter] = useState(() => JSON.parse(sessionStorage.getItem('priorityFilter')) || []);
  const [treatmentFilter, setTreatmentFilter] = useState(() => JSON.parse(sessionStorage.getItem('treatmentFilter')) || []);
  const [treatmentOptions, setTreatmentOptions] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const token = localStorage.getItem('token');
  const start_position = localStorage.getItem('start_position');
  const end_position = localStorage.getItem('end_position');

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        let url;
        if (rute_omrade_id === "all") {
          url = `${config.apiUrl}/getAddressesAssignmentPriAll/?token=${token}`;
        } else {
          url = `${config.apiUrl}/getAddressesAssignmentPri/${rute_omrade_id}/?token=${token}`;
        }

        const response = await apiClient.get(url);
        if (response.data.status === 'success') {
          const uniqueAddresses = deduplicateAddresses(response.data.data);
          setAddresses(uniqueAddresses);
          setSortedAddresses(uniqueAddresses);
        }
      } catch (error) {
        console.error('Error fetching addresses:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchTreatmentOptions = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getCheckType/?token=${token}`);
        if (response.data.status === 'success') {
          setTreatmentOptions(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching treatment options:', error);
      }
    };

    fetchAddresses();
    fetchTreatmentOptions();
  }, [rute_omrade_id, token]);

  const handleCardClick = (behandlingsadr_id) => {
    navigate(`/serviceaddress/${behandlingsadr_id}`);
  };

  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
    if (criteria === 'optimal') {
      calculateOptimalRoute();
    } else {
      sortAndFilterAddresses(criteria);
    }
  };

  const handleFilterChange = (priorityFilter, treatmentFilter) => {
    setPriorityFilter(priorityFilter);
    setTreatmentFilter(treatmentFilter);
    sessionStorage.setItem('priorityFilter', JSON.stringify(priorityFilter));
    sessionStorage.setItem('treatmentFilter', JSON.stringify(treatmentFilter));
    if (sortCriteria === 'optimal') {
      calculateOptimalRoute();
    } else {
      sortAndFilterAddresses(sortCriteria);
    }
  };

  const handleFilterReset = () => {
    setPriorityFilter([]);
    setTreatmentFilter([]);
    sessionStorage.removeItem('priorityFilter');
    sessionStorage.removeItem('treatmentFilter');
    if (sortCriteria !== 'optimal') {
      sortAndFilterAddresses(sortCriteria);
    } else {
      calculateOptimalRoute();
    }
  };

  const deduplicateAddresses = (addresses) => {
    const uniqueAddresses = new Map();
    addresses.forEach(address => {
      if (!uniqueAddresses.has(address.behandlingsadr_id)) {
        uniqueAddresses.set(address.behandlingsadr_id, address);
      }
    });
    return Array.from(uniqueAddresses.values());
  };

  const sortAndFilterAddresses = (criteria) => {
    let filtered = filterAddresses(addresses);

    if (criteria === 'priority') {
      filtered.sort((a, b) => (a.pri_color > b.pri_color) ? 1 : -1);
    } else if (criteria === 'postnr') {
      filtered.sort((a, b) => (a.postnr > b.postnr) ? 1 : -1);
    }

    setSortedAddresses(deduplicateAddresses(filtered));
  };

  const filterAddresses = (addresses) => {
    return deduplicateAddresses(addresses.filter(address =>
      (priorityFilter.length === 0 || priorityFilter.includes(address.pri_color)) &&
      (treatmentFilter.length === 0 || 
        treatmentFilter.some(id => {
          const treatmentOption = treatmentOptions.find(option => option.behandling_id === id);
          return treatmentOption && address.behandling && address.behandling.includes(treatmentOption.behandling);
        })
      ) &&
      ((address.navn && address.navn.toLowerCase().includes(searchQuery.toLowerCase())) ||
       (address.behandlingsadr && address.behandlingsadr.toLowerCase().includes(searchQuery.toLowerCase())) ||
       (address.poststed && address.poststed.toLowerCase().includes(searchQuery.toLowerCase())) ||
       (address.postnr && address.postnr.toLowerCase().includes(searchQuery.toLowerCase())))
    ));
  };

  const calculateOptimalRoute = () => {
    const filteredAddresses = filterAddresses(addresses);
    setSortedAddresses(filteredAddresses);
  };

  useEffect(() => {
    sortAndFilterAddresses(sortCriteria);
  }, [priorityFilter, treatmentFilter, searchQuery]);

  const filteredAddresses = filterAddresses(sortedAddresses);

  const handleFilterButtonClick = () => {
    if (sortCriteria !== 'optimal') {
      setShowFilter(!showFilter);
    }
  };

  return (
    <MainLayout title="Addresses">
      <div className="addresses-container">
        <div className="sort-container">
          <input
            type="text"
            placeholder="Search by name, address, postal code or city"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-box"
            disabled={sortCriteria === 'optimal'}
          />
          <SortSelector 
            sortCriteria={sortCriteria}
            handleSortChange={handleSortChange} 
            showOptimalRouteOption={rute_omrade_id !== "all"} 
          />
          <button className="filter-button" onClick={handleFilterButtonClick} disabled={sortCriteria === 'optimal'}>
            <FaFilter style={{ marginRight: '5px' }} />
            Filter
          </button>
        </div>
        {showFilter && sortCriteria !== 'optimal' && (
          <div className="filter-container">
            <div className="filter-actions">
              <button className="filter-close-button" onClick={() => setShowFilter(false)}>Close</button>
              <button className="filter-reset-button" onClick={handleFilterReset}>Reset</button>
            </div>
            <Filter
              priorityFilter={priorityFilter}
              treatmentFilter={treatmentFilter}
              treatmentOptions={treatmentOptions}
              setPriorityFilter={setPriorityFilter}
              setTreatmentFilter={setTreatmentFilter}
              handleFilterChange={handleFilterChange}
              disabled={sortCriteria === 'optimal'}
            />
          </div>
        )}
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {sortCriteria === 'optimal' && (
              <div className="optimal-route-message">
                <h2>Best driving route between filtered service addresses</h2>
                <p>The route is created using your start and end positions, as registered in your profile. Filtering options are not available while the optimal route is displayed. However, you can change the sorting and calculate a new optimal route for the updated selection.</p>
              </div>
            )}
            {sortCriteria === 'optimal' ? (
              <OptimalRoute addresses={filteredAddresses} setSortedAddresses={setSortedAddresses} setDistances={setDistances} />
            ) : (
              filteredAddresses.map(address => (
                <AddressCard 
                  key={address.behandlingsadr_id} 
                  address={address} 
                  distances={distances} 
                  handleCardClick={handleCardClick} 
                  showDistance={sortCriteria === 'optimal'} 
                />
              ))
            )}
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default Addresses;
