import React, { useState, useEffect, useRef, useMemo } from 'react';
import MainLayout from '../components/MainLayout';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import config from '../config';
import './NewInspection.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';

const NewInspection = () => {
  // Get the inspectionId from the URL parameters
  const { inspectionId } = useParams();

  // Define state variables to hold form input values
  const [customerName, setCustomerName] = useState('');
  const [inspectionTypes, setInspectionTypes] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [project, setProject] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [aptNumber, setAptNumber] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  
  // Initialize navigate function from react-router-dom to navigate between pages
  const navigate = useNavigate();

  // Use useMemo to memoize the libraries array, which is used for loading Google Maps script
  const libraries = useMemo(() => ['places'], []);

  // Load the Google Maps script with the specified API key and libraries
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleMapsApiKey,
    libraries,
    id: 'google-maps-script',
  });

  // Get the token and technician_id from localStorage
  const token = localStorage.getItem('token');
  const technician_id = parseInt(localStorage.getItem('userid'), 10);

  // Create a ref to hold the Autocomplete instance
  const autocompleteRef = useRef(null);

  // Fetch inspection types when the component mounts
  useEffect(() => {
    apiClient.get(`${config.apiUrl}/getInsType/?token=${token}`)
      .then(response => {
        if (response.data.status === 'success') {
          setInspectionTypes(response.data.data);
        }
      })
      .catch(error => {
        console.error('Error fetching inspection types:', error);
      });
  }, [token]);

  // Fetch inspection details if inspectionId is present
  useEffect(() => {
    if (inspectionId) {
      apiClient.get(`${config.apiUrl}/getInspectionNew/${inspectionId}/?token=${token}`)
        .then(response => {
          if (response.data.status === 'success') {
            const inspection = response.data.data;
            // Populate state variables with the fetched inspection details
            setCustomerName(inspection.customer_name);
            setSelectedType(inspection.inspection_type_id);
            setContact(inspection.contact_name);
            setEmail(inspection.mail);
            setTelephone(inspection.phone);
            setStreetAddress(inspection.inspection_addr);
            setAptNumber(inspection.unit_number);
            setPostalCode(inspection.postal_code);
            setCity(inspection.city);
          }
        })
        .catch(error => {
          console.error('Error fetching inspection:', error);
        });
    }
  }, [inspectionId, token]);

  // Handle address changes using Google Maps Autocomplete
  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place) {
      const addressComponents = place.address_components;
      const streetNumber = addressComponents.find(comp => comp.types.includes('street_number'))?.long_name || '';
      const route = addressComponents.find(comp => comp.types.includes('route'))?.long_name || '';
      const postalCode = addressComponents.find(comp => comp.types.includes('postal_code'))?.long_name || '';
      const city = addressComponents.find(comp => comp.types.includes('postal_town'))?.long_name || '';

      setStreetAddress(`${route} ${streetNumber}`);
      setPostalCode(postalCode);
      setCity(city);
    }
  };

  // Handle the "Next" button click event
  const handleNext = () => {
    // Prepare the payload to be sent in the API request
    const payload = {
      date: new Date().toISOString(),
      customer_name: customerName,
      inspection_addr: streetAddress,
      postal_code: postalCode,
      city: city,
      phone: telephone,
      mail: email,
      contact_name: contact,
      technician_id: technician_id,
      inspection_type_id: selectedType,
      unit_number: aptNumber,
      inspection_id: inspectionId
    };

    // If inspectionId is present, update the inspection
    if (inspectionId) {
      apiClient.post(`${config.apiUrl}/updInspectionNew/?token=${token}`, payload)
        .then(response => {
          if (response.data.status === 'success') {
            navigate(`/inspectiondetails/${inspectionId}`);
          }
        })
        .catch(error => {
          console.error('Error updating inspection:', error);
        });
    } else {
      // If inspectionId is not present, create a new inspection
      apiClient.post(`${config.apiUrl}/addInspectionNew/?token=${token}`, payload)
        .then(response => {
          if (response.data.status === 'success') {
            navigate(`/inspectiondetails/${response.data.inspection_id}`);
          }
        })
        .catch(error => {
          console.error('Error adding inspection:', error);
        });
    }
  };

  // Handle the "Cancel" button click event
  const handleCancel = () => {
    if (inspectionId) {
      if (window.confirm('The inspection will be deleted if you navigate away. Do you want to proceed?')) {
        apiClient.delete(`${config.apiUrl}/delInspectionNew/${inspectionId}/?token=${token}`)
          .then(response => {
            if (response.data.status === 'success') {
              navigate('/mainmenu');
            }
          })
          .catch(error => {
            console.error('Error deleting inspection:', error);
          });
      }
    } else {
      navigate('/mainmenu');
    }
  };

  // Handle loading errors for Google Maps script
  if (loadError) {
    return <div>Error loading maps</div>;
  }

  // Show loading message until Google Maps script is loaded
  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <MainLayout title="New Inspection">
      <div className="ins-container">
        <div className="ins-field">
          <label>Customer Name</label>
          <input
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            className={`ins-input ${!customerName ? 'ins-input-required' : 'ins-input-filled'}`}
          />
        </div>
        <div className="ins-field">
          <label>Choose Type</label>
          <select
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
            className={`select-input ${!selectedType ? 'ins-input-required' : 'ins-input-filled'}`}
          >
            <option value="">Select Type</option>
            {inspectionTypes.map(type => (
              <option key={type.inspection_type_id} value={type.inspection_type_id}>{type.type}</option>
            ))}
          </select>
        </div>
        <div className="ins-field">
          <label>Project</label>
          <input
            type="text"
            value={project}
            onChange={(e) => setProject(e.target.value)}
            className="ins-input"
          />
        </div>
        <div className="ins-field">
          <label>Contact</label>
          <input
            type="text"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            className="ins-input"
          />
        </div>
        <div className="ins-field">
          <label>E-mail Address</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="ins-input"
          />
        </div>
        <div className="ins-field">
          <label>Telephone</label>
          <input
            type="tel"
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
            className="ins-input"
          />
        </div>
        <div className="ins-field-inline">
          <div className="ins-field ins-flex-2">
            <label>Street Address</label>
            <Autocomplete
              onLoad={(ref) => (autocompleteRef.current = ref)}
              onPlaceChanged={handlePlaceChanged}
              options={{ componentRestrictions: { country: 'no' } }}
            >
              <input
                type="text"
                value={streetAddress}
                onChange={(e) => setStreetAddress(e.target.value)}
                className={`ins-input ${!streetAddress ? 'ins-input-required' : 'ins-input-filled'}`}
              />
            </Autocomplete>
          </div>
          <div className="ins-field ins-flex-1">
            <label>Apt No</label>
            <input
              type="text"
              value={aptNumber}
              onChange={(e) => setAptNumber(e.target.value)}
              className="ins-input"
            />
          </div>
        </div>
        <div className="ins-field-inline">
          <div className="ins-field ins-flex-1">
            <label>Postal/Zip</label>
            <input
              type="text"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              className={`ins-input ${!postalCode ? 'ins-input-required' : 'ins-input-filled'}`}
            />
          </div>
          <div className="ins-field ins-flex-2">
            <label>City</label>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className={`ins-input ${!city ? 'ins-input-required' : 'ins-input-filled'}`}
            />
          </div>
        </div>
        <div className="ins-buttons">
          <button className="ins-button ins-button-cancel" onClick={handleCancel}>Cancel</button>
          <button className="ins-button ins-button-next" onClick={handleNext}>Next</button>
        </div>
        {inspectionId && (
          <div className="inspection-id">
            <p>Inspection ID: {inspectionId}</p>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default NewInspection;
