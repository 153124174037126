// src/components/OppdragInfoBanner.js
import React, { useEffect, useState } from 'react';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '../contexts/ToastContext';
import './OppdragInfoBanner.css';
import config from '../config';

const OppdragInfoBanner = () => {
  const { behandlingsadrId, oppdrag_id } = useParams();
  const [oppdragInfo, setOppdragInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const notify = useToast();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchOppdragInfo = async () => {
      try {
        const response = await apiClient.post(`${config.apiUrl}/getOppdragInfoBanner/?token=${token}`, {
            behandlingsadr_id: behandlingsadrId,
            oppdrag_id: oppdrag_id,
          
        });
        if (response.data && response.data.status === 'success' && response.data.data.length > 0) {
          setOppdragInfo(response.data.data[0]);
        } else {
          throw new Error('No data returned');
        }
      } catch (error) {
        notify('error', 'Failed to fetch oppdrag info. Redirecting...');
        setTimeout(() => {
          navigate('/mainmenu');
        }, 3000);
      } finally {
        setLoading(false);
      }
    };

    fetchOppdragInfo();
  }, [behandlingsadrId, oppdrag_id, token, notify, navigate]);

  if (loading) {
    return <div className="banner-loading">Loading oppdrag info...</div>;
  }

  return (
    <div className="oppdrag-info-banner">
      {oppdragInfo ? (
        <>
          <p><strong>Kunde:</strong> {oppdragInfo.kunde_navn} - {oppdragInfo.behandlingsadresse}</p>
          <p><strong>Avtale:</strong> {oppdragInfo.avtale_navn} - Oppdrag: {oppdragInfo.oppdrag_id} </p>
          
        </>
      ) : (
        <p>Ingen oppdrag info tilgjengelig.</p>
      )}
    </div>
  );
};

export default OppdragInfoBanner;
